import React from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'
import NumberFormat from 'react-number-format'

import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import CardWrapper from '../CardWrapper/dynamic'

import TimeAndCountStyled from './styled'

import { withTranslation } from '../../../i18n'

export class TimeAndCountCard extends React.PureComponent {
  renderTime(count) {
    const { time, dataTestTime } = this.props
    let output = null
    if (count > 0) {
      output = (
        <div className="time-wrapper flex" data-test={dataTestTime}>
          {time}
        </div>
      )
    }
    if (this.props.loading) {
      output = (
        <div className="time-wrapper flex" data-test={dataTestTime}>
          <SkeletonAnimation id="time-skeleton-loading" className="loading" />
        </div>
      )
    }
    return output
  }

  renderValue() {
    const { count, dataTestCount } = this.props
    let output = (
      <div className="count-wrapper flex" data-test={dataTestCount}>
        <NumberFormat value={count} displayType={'text'} thousandSeparator={true} />
        {pluralize(this.props.t('count'), count)}
      </div>
    )
    if (this.props.loading) {
      output = (
        <div className="count-wrapper flex" data-test={dataTestCount}>
          <SkeletonAnimation id="value-skeleton-loading" className="loading" />
        </div>
      )
    }
    return output
  }

  renderCardContent() {
    const { count } = this.props
    const className = count > 0 || this.props.loading ? '' : 'no-data'
    return (
      <div className={`content-wrapper ${className}`}>
        {this.renderTime(count)}
        {this.renderValue()}
      </div>
    )
  }

  renderCard() {
    const { cardTitle, dataTestTitle } = this.props
    return (
      <CardWrapper title={cardTitle} id={dataTestTitle}>
        {this.renderCardContent()}
      </CardWrapper>
    )
  }

  render() {
    return <TimeAndCountStyled className={this.props.className}>{this.renderCard()}</TimeAndCountStyled>
  }
}

TimeAndCountCard.defaultProps = {
  loading: false,
}

TimeAndCountCard.propTypes = {
  className: PropTypes.string,
  cardTitle: PropTypes.string.isRequired,
  dataTestTitle: PropTypes.string,
  dataTestTime: PropTypes.string,
  dataTestCount: PropTypes.string,
  time: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(TimeAndCountCard)
