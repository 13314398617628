import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.div`
  height: 100%;
  .content-wrapper {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    .time-wrapper {
      font-size: 16px;
      font-family: 'Prompt';
      color: #a60004;
      .loading {
        width: 60px;
        height: 10px;
      }
    }
    .count-wrapper {
      span {
        font-size: 36px;
        font-family: 'Prompt';
        color: #f2f2f2;
        margin-right: 12px;
      }
      align-items: baseline;
      font-family: 'Prompt';
      font-size: 12px;
      text-transform: uppercase;
      .loading {
        width: 80px;
        height: 34px;
      }
    }
    &.no-data {
      justify-content: flex-end;
    }
  }
  @media only screen and (min-width: ${device.minSizeL}) and (max-width: ${device.maxSizeL}) {
    .content-wrapper {
      .time-wrapper {
        font-size: 12px;
      }
      .count-wrapper {
        span {
          font-size: 21px;
        }
        font-size: 12px;
      }
    }
  }
  @media only screen and (min-width: ${device.minSizeM}) and (max-width: ${device.maxSizeM}) {
    .content-wrapper {
      .time-wrapper {
        font-size: 16px;
      }
      .count-wrapper {
        span {
          font-size: 24px;
        }
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: ${device.maxSizeS}) {
    .content-wrapper {
      .time-wrapper {
        font-size: 16px;
      }
      .count-wrapper {
        span {
          font-size: 24px;
        }
        font-size: 12px;
      }
    }
  }
`
